import Account from '@iso/redux/account/reducer';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Common from '@iso/redux/common/reducer';
import Consignee from '@iso/redux/consignee/reducer';
import Container from '@iso/redux/containers/reducer';
import ShippingCategory from '@iso/redux/shippingCategory/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Pallet from '@iso/redux/pallets/reducer';
import Product from '@iso/redux/products/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import User from '@iso/redux/users/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Account,
  Common,
  User,
  Consignee,
  Product,
  Pallet,
  Container,
  ShippingCategory
});
