const actions = {

    //#region VERSION
    GET_VERSION_API: 'GET_VERSION_API',
    GET_VERSION_API_SUCCESS: 'GET_VERSION_API_SUCCESS',
    GET_VERSION_API_ERROR: 'GET_VERSION_API_ERROR',

    getVersionApi: () => ({
        type: actions.GET_VERSION_API,
    }),
    //#endregion

    //#region GROUPS
    GET_GROUPS_COMMON: 'GET_GROUPS_COMMON',
    GET_GROUPS_COMMON_SUCCESS: 'GET_GROUPS_COMMON_SUCCESS',
    getGroupsCommon: () => ({
        type: actions.GET_GROUPS_COMMON,
    }),
    //#endregion

    //#region GROUPS
    GET_PROFILE_TYPES_COMMON: 'GET_PROFILE_TYPES_COMMON',
    GET_PROFILE_TYPES_COMMON_SUCCESS: 'GET_PROFILE_TYPES_COMMON_SUCCESS',
    getProfileTypesCommon: () => ({
        type: actions.GET_PROFILE_TYPES_COMMON,
    }),
    //#endregion

    //#region  ADDRESS
    GET_PROVINCE_COMMON: 'GET_PROVINCE_COMMON',
    GET_PROVINCE_COMMON_SUCCESS: 'GET_PROVINCE_COMMON_SUCCESS',
    getProvinces: () => ({
        type: actions.GET_PROVINCE_COMMON,
    }),

    GET_DISTRICT_COMMON: 'GET_DISTRICT_COMMON',
    GET_DISTRICT_COMMON_SUCCESS: 'GET_DISTRICT_COMMON_SUCCESS',
    RESET_DISTRICT_COMMON: 'RESET_DISTRICT_COMMON',
    getDistricts: provinceId => ({
        type: actions.GET_DISTRICT_COMMON,
        payload: { provinceId }
    }),
    resetDistricts: () => ({
        type: actions.RESET_DISTRICT_COMMON,
    }),

    GET_WARD_COMMON: 'GET_WARD_COMMON',
    GET_WARD_COMMON_SUCCESS: 'GET_WARD_COMMON_SUCCESS',
    RESET_WARD_COMMON: 'RESET_WARD_COMMON',
    getWards: districtId => ({
        type: actions.GET_WARD_COMMON,
        payload: { districtId }
    }),
    resetWards: () => ({
        type: actions.RESET_WARD_COMMON,
    }),
    //#endregion

    COMMON_RESPONSE_ERROR: 'COMMON_RESPONSE_ERROR',

    GET_COMMON_PRODUCTS_CARTON: "GET_COMMON_PRODUCTS_CARTON",
    GET_COMMON_PRODUCTS_CARTON_SUCCESS: "GET_COMMON_PRODUCTS_CARTON_SUCCESS",
    GET_COMMON_PRODUCTS_CARTON_ERROR: "GET_COMMON_PRODUCTS_CARTON_ERROR",

    getCommonProductsCarton: (model, _cbError) => ({
        type: actions.GET_COMMON_PRODUCTS_CARTON,
        payload: { model },
        _cbError,
    }),

    GET_COMMON_HARD_TEXT: "GET_COMMON_HARD_TEXT",
    GET_COMMON_HARD_TEXT_SUCCESS: "GET_COMMON_HARD_TEXT_SUCCESS",
    GET_COMMON_HARD_TEXT_ERROR: "GET_COMMON_HARD_TEXT_ERROR",

    getCommonHardText: (_cbError) => ({
        type: actions.GET_COMMON_HARD_TEXT,
        _cbError,
    }),

    UPDATE_COMMON_HARD_TEXT: "UPDATE_COMMON_HARD_TEXT",
    updateCommonHardText: (model, _cbSuccess, _cbError) => ({
        payload: { model },
        type: actions.UPDATE_COMMON_HARD_TEXT,
        _cbSuccess, _cbError,
    }),

    CLEAR_COMMON_STORAGE: "CLEAR_COMMON_STORAGE",
    clearCommonStorage: () => ({
        type: actions.CLEAR_COMMON_STORAGE,
    }),
}

export default actions;