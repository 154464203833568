const actions = {

    GET_SHIPPING_CATEGORY: 'GET_SHIPPING_CATEGORY',
    GET_SHIPPING_CATEGORY_SUCCESS: 'GET_SHIPPING_CATEGORY_SUCCESS',
    GET_SHIPPING_CATEGORY_ERROR: 'GET_SHIPPING_CATEGORY_ERROR',

    GET_SHIPPING_CATEGORY_DETAIL: 'GET_SHIPPING_CATEGORY_DETAIL',
    GET_SHIPPING_CATEGORY_DETAIL_SUCCESS: 'GET_SHIPPING_CATEGORY_DETAIL_SUCCESS',
    GET_SHIPPING_CATEGORY_DETAIL_ERROR: 'GET_SHIPPING_CATEGORY_DETAIL_ERROR',

    CREATE_SHIPPING_CATEGORY: 'CREATE_SHIPPING_CATEGORY',
    UPDATE_SHIPPING_CATEGORY: 'UPDATE_SHIPPING_CATEGORY',

    DELETE_SHIPPING_CATEGORY: 'DELETE_SHIPPING_CATEGORY',

    //#region CRUD
    getShippingCategory: (model, _cbError) => ({
        type: actions.GET_SHIPPING_CATEGORY,
        payload: { model },
        _cbError,
    }),
    deleteShippingCategory: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_SHIPPING_CATEGORY,
        payload: { id },
        cbSuccess, cbError
    }),
    getShippingCategoryDetail: id => ({
        type: actions.GET_SHIPPING_CATEGORY_DETAIL,
        payload: { id }
    }),
    createShippingCategory: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_SHIPPING_CATEGORY,
        payload: { model },
        cbSuccess, cbError
    }),
    updateShippingCategory: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_SHIPPING_CATEGORY,
        payload: { model },
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;