import accountSaga from '@iso/redux/account/saga';
import authSaga from '@iso/redux/auth/saga';
import commonSaga from '@iso/redux/common/saga';
import consigneeSaga from '@iso/redux/consignee/saga';
import shippingCategorySaga from '@iso/redux/shippingCategory/saga';
import containerSaga from '@iso/redux/containers/saga';
import palletSaga from '@iso/redux/pallets/saga';
import productSaga from '@iso/redux/products/saga';
import userSaga from '@iso/redux/users/saga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    commonSaga(),
    userSaga(),
    consigneeSaga(),
    productSaga(),
    palletSaga(),
    containerSaga(),
    shippingCategorySaga(),
  ]);
}
