import * as shippingCategoryServices from '@iso/services/shippingCategory';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region CRUD
export function* getShippingCategorySaga() {
  yield takeEvery(actions.GET_SHIPPING_CATEGORY, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(shippingCategoryServices.getShippingCategoryService, model);
      if (response.data.success) {
        var data = response.data.data.list;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_SHIPPING_CATEGORY_SUCCESS,
          payload: { data, totalItems }
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_SHIPPING_CATEGORY_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}
export function* deleteShippingCategorySaga() {
  yield takeEvery(actions.DELETE_SHIPPING_CATEGORY, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(shippingCategoryServices.deleteShippingCategoryService, id);
      if (response.data.success === true) {
        yield cbSuccess();
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* getShippingCategoryDetailSaga() {
  yield takeEvery(actions.GET_SHIPPING_CATEGORY_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(shippingCategoryServices.getShippingCategoryDetailService, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_SHIPPING_CATEGORY_DETAIL_SUCCESS,
          payload: { data }
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_SHIPPING_CATEGORY_DETAIL_ERROR,
      })
    }
  })
}
export function* createShippingCategorySaga() {
  yield takeEvery(actions.CREATE_SHIPPING_CATEGORY, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(shippingCategoryServices.createShippingCategoryService, model);
      if (response.data.success === true) {
        yield cbSuccess();
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* updateShippingCategorySaga() {
  yield takeEvery(actions.UPDATE_SHIPPING_CATEGORY, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(shippingCategoryServices.updateShippingCategoryService, model);
      if (response.data.success === true) {
        yield cbSuccess();
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getShippingCategorySaga),
    fork(deleteShippingCategorySaga),
    fork(getShippingCategoryDetailSaga),
    fork(createShippingCategorySaga),
    fork(updateShippingCategorySaga),
  ]);
}