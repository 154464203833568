import * as consigneeServices from '@iso/services/consignee';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region CRUD
export function* getConsigneeSaga() {
  yield takeEvery(actions.GET_CONSIGNEE, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(consigneeServices.getConsigneeService, model);
      if (response.data.success) {
        var data = response.data.data.list;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_CONSIGNEE_SUCCESS,
          payload: { data, totalItems }
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_CONSIGNEE_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}
export function* deleteConsigneeSaga() {
  yield takeEvery(actions.DELETE_CONSIGNEE, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(consigneeServices.deleteConsigneeService, id);
      if (response.data.success === true) {
        yield cbSuccess();
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* getConsigneeDetailSaga() {
  yield takeEvery(actions.GET_CONSIGNEE_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(consigneeServices.getConsigneeDetailService, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_CONSIGNEE_DETAIL_SUCCESS,
          payload: { data }
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_CONSIGNEE_DETAIL_ERROR,
      })
    }
  })
}
export function* createConsigneeSaga() {
  yield takeEvery(actions.CREATE_CONSIGNEE, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(consigneeServices.createConsigneeService, model);
      if (response.data.success === true) {
        yield cbSuccess();
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* updateConsigneeSaga() {
  yield takeEvery(actions.UPDATE_CONSIGNEE, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(consigneeServices.updateConsigneeService, model);
      if (response.data.success === true) {
        yield cbSuccess();
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getConsigneeSaga),
    fork(deleteConsigneeSaga),
    fork(getConsigneeDetailSaga),
    fork(createConsigneeSaga),
    fork(updateConsigneeSaga),
  ]);
}