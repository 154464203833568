import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';

export const getVersionApi = () => {
  var url = `${ApiRouters.COMMON}/GetVersionApi`;
  return api.get(url);
};

//#region GROUPS
export const getGroupsCommon = () => {
  var url = `${ApiRouters.COMMON}/Groups`;
  return api.get(url);
};
//#endregion

//#region PROFILE TYPES
export const getProfileTypesCommon = () => {
  var url = `${ApiRouters.COMMON}/ProfileTypes`;
  return api.get(url);
};
//#endregion

//#region ADDRESS
export const getProvinces = () => {
  var url = `${ApiRouters.COMMON}/Provinces`;
  return api.get(url);
};
export const getDistricts = provinceId => {
  var url = `${ApiRouters.COMMON}/Provinces/${provinceId}/Districts`;
  return api.get(url);
};
export const getWards = districtId => {
  var url = `${ApiRouters.COMMON}/Districts/${districtId}/Wards`;
  return api.get(url);
};
//#endregion

export const getCommonProductsCartonService = model => {
  var url = `${ApiRouters.COMMON}/getListProductCarton/${model.id}`;
  return api.get(url, { params: model });
};

export const getCommonHardTextService = () => {
  var url = `${ApiRouters.COMMON}/getListHardText`;
  return api.get(url);
};

export const updateCommonHardTextService = (body) => {
  var url = `${ApiRouters.COMMON}/updateListHartText`;
  return api.put(url, body);
};


