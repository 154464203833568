import * as containerService from "@iso/services/container";
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

export function* getContainersSaga() {
  yield takeEvery(actions.GET_CONTAINERS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(containerService.getContainersService, model);
      if (response.data.success) {
        var data = response.data.data.list;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_CONTAINERS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_CONTAINERS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* getContainerDetailSaga() {
  yield takeEvery(
    actions.GET_CONTAINER_DETAIL,
    function* ({ payload, _cbError }) {
      const { containerId } = payload;
      try {
        var response = yield call(
          containerService.getContainerDetailService,
          containerId
        );
        if (response.data.success) {
          var data = response.data.data;
          yield put({
            type: actions.GET_CONTAINER_DETAIL_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.GET_CONTAINER_DETAIL_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* createContainerSaga() {
  yield takeEvery(
    actions.CREATE_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        var response = yield call(
          containerService.createContainerService,
          body
        );
        if (response.data.success) {
          let { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* updateContainerSaga() {
  yield takeEvery(
    actions.UPDATE_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        var response = yield call(
          containerService.updateContainerService,
          body
        );
        if (response.data.success) {
          let { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* createBLSaga() {
  yield takeEvery(
    actions.CREATE_BL,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(containerService.createBLService, model);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* cancelContainerSaga() {
  yield takeEvery(
    actions.CANCEL_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          containerService.cancelContainerService,
          model?.id
        );
        if (response.data.success) {
          yield _cbSuccess(model?.type);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* deleteContainerSaga() {
  yield takeEvery(
    actions.DELETE_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          containerService.deleteContainerService,
          model?.id
        );
        if (response.data.success) {
          yield _cbSuccess(model?.type);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* getPalletsContainerSaga() {
  yield takeEvery(
    actions.GET_PALLETS_CONTAINER,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          containerService.getPalletsContainerService,
          model
        );
        if (response.data.success) {
          let { list: data, total, sum_total_weight } = response.data.data;
          yield put({
            type: actions.GET_PALLETS_CONTAINER_SUCCESS,
            payload: { data, total, sum_total_weight },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.GET_PALLETS_CONTAINER_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* addPalletsContainerSaga() {
  yield takeEvery(
    actions.ADD_PALLETS_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          containerService.addPalletsContainerService,
          model
        );
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* deletePalletContainerSaga() {
  yield takeEvery(
    actions.DELETE_PALLET_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { containerId, model } = payload;
      try {
        var response = yield call(
          containerService.deletePalletContainerService,
          containerId,
          model
        );
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* exportPDFContainerSaga() {
  yield takeEvery(
    actions.EXPORT_PDF_CONTAINER,
    function* ({ payload, _cbError }) {
      const { containerId } = payload;
      try {
        var response = yield call(
          containerService.exportPDFContainerService,
          containerId
        );
        if (response.data.success) {
          let { data } = response.data;
          yield put({
            type: actions.EXPORT_PDF_CONTAINER_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.EXPORT_PDF_CONTAINER_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* exportExcelContainerSaga() {
  yield takeEvery(
    actions.EXPORT_EXCEL_CONTAINER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { containerId } = payload;
      try {
        var response = yield call(
          containerService.exportExcelContainerService,
          containerId
        );

        let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
        if (response.data) {
          yield _cbSuccess();
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Containers_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.StatusCode === 409
        ) {
          yield _cbError(e.response.data.ErrorMessage);
        } else {
          yield _cbError();
        }
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getContainersSaga),
    fork(getContainerDetailSaga),
    fork(createContainerSaga),
    fork(updateContainerSaga),
    fork(cancelContainerSaga),
    fork(deleteContainerSaga),
    fork(createBLSaga),
    fork(getPalletsContainerSaga),
    fork(addPalletsContainerSaga),
    fork(deletePalletContainerSaga),
    fork(exportPDFContainerSaga),
    fork(exportExcelContainerSaga),
  ]);
}
