import actions from './actions';

const initState = {
  shippingCategory: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  shippingCategoryDetail: {
    detail: {},
    loading: false,
  },
};

export default function shippingCategoryReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_SHIPPING_CATEGORY:
      return { ...state, shippingCategory: { ...state.shippingCategory, loading: true } };
    case actions.GET_SHIPPING_CATEGORY_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, shippingCategory: { ...state.shippingCategory, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_SHIPPING_CATEGORY_ERROR:
      return { ...state, shippingCategory: { ...state.shippingCategory, loading: false, totalItems: 0, list: [] } };

    case actions.GET_SHIPPING_CATEGORY_DETAIL:
      return { ...state, shippingCategoryDetail: { ...state.shippingCategoryDetail, loading: true } };
    case actions.GET_SHIPPING_CATEGORY_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, shippingCategoryDetail: { ...state.shippingCategoryDetail, loading: false, detail: res } };
    }
    case actions.GET_SHIPPING_CATEGORY_DETAIL_ERROR:
      return { ...state, shippingCategoryDetail: { ...state.shippingCategoryDetail, loading: false } };
    //#endregion

    default:
      return state;

  }
}
