import * as productsService from "@iso/services/product";
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD
export function* getProductsSaga() {
  yield takeEvery(actions.GET_PRODUCTS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(productsService.getProductsService, model);
      if (response.data.success) {
        var data = response.data.data.list;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_PRODUCTS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_PRODUCTS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}
export function* deleteProductSaga() {
  yield takeEvery(
    actions.DELETE_PRODUCT,
    function* ({ payload, cbSuccess, cbError }) {
      const { listId } = payload;
      try {
        var response = yield call(productsService.deleteProductService, listId);
        if (response.data.success) {
          yield cbSuccess();
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export function* getProductDetailSaga() {
  yield takeEvery(actions.GET_PRODUCT_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(productsService.getProductDetailService, id);
      if (response.data.success) {
        var data = response.data.data;
        yield put({
          type: actions.GET_PRODUCT_DETAIL_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_PRODUCT_DETAIL_ERROR,
      });
    }
  });
}
export function* createProductSaga() {
  yield takeEvery(
    actions.CREATE_PRODUCT,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(productsService.createProductService, model);
        if (response.data.success) {
          yield cbSuccess();
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export function* updateProductSaga() {
  yield takeEvery(
    actions.UPDATE_PRODUCT,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      console.log("call saga")
      try {
        let response = null;
        if (model.id) {
          response = yield call(productsService.updateProductService, model);
        } else {
          response = yield call(productsService.createProductService, model);
        }
        if (response.data.success) {
          yield cbSuccess();
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export function* importProductsSaga() {
  yield takeEvery(
    actions.IMPORT_PRODUCTS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(productsService.importProductsService, model);
        if (response.status === 201) {
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `ImportProductsResult_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          yield _cbSuccess();
        }
        else {
          if (response.status === 202) {
            _cbError("FILE_MALFORMED");
          }
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* tempalteImportProductsSaga() {
  yield takeEvery(
    actions.TEMPLATE_IMPORT_PRODUCTS,
    function* ({ _cbSuccess, _cbError }) {
      try {
        var response = yield call(productsService.templateImportProductsService);
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `TemplateImportProducts_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.ErrorMessage);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* exportProductsSaga() {
  yield takeEvery(
    actions.EXPORT_PRODUCTS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(productsService.exportProductsService, model);
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `${model.name}_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.ErrorMessage);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getProductsSaga),
    fork(deleteProductSaga),
    fork(getProductDetailSaga),
    fork(createProductSaga),
    fork(updateProductSaga),
    fork(importProductsSaga),
    fork(tempalteImportProductsSaga),
    fork(exportProductsSaga),
  ]);
}
