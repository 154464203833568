import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 120000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}

export const getProductsService = (model) => {
  var url = ApiRouters.PRODUCT;
  return api.get(url, { params: model });
};
export const deleteProductService = (listId) => {
  let body = { listId };
  var url = `${ApiRouters.PRODUCT}`;
  return api.put(url, body);
};
export const getProductDetailService = (id) => {
  var url = `${ApiRouters.PRODUCT}/${id}`;
  return api.get(url);
};
export const createProductService = (body) => {
  var url = ApiRouters.PRODUCT;
  return api.post(url, body);
};
export const updateProductService = (body) => {
  var url = `${ApiRouters.PRODUCT}/${body.id}`;
  return api.put(url, body);
};
export const importProductsService = (body) => {
  var url =
    process.env.REACT_APP_API_KEY + `${ApiRouters.PRODUCT}/importProduct`;
  return axios(optionsAxios(url, "post", null, body));
};
export const templateImportProductsService = () => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.PRODUCT}/Template/importProduct`;
  return axios(optionsAxios(url, "get", null, null));
};
export const exportProductsService = (model) => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.PRODUCT}/Template/exportProducts/${model.type}`;
  return axios(optionsAxios(url, "get", null, null));
};
