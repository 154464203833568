const actions = {

    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',

    GET_PRODUCT_DETAIL: 'GET_PRODUCT_DETAIL',
    GET_PRODUCT_DETAIL_SUCCESS: 'GET_PRODUCT_DETAIL_SUCCESS',
    GET_PRODUCT_DETAIL_ERROR: 'GET_PRODUCT_DETAIL_ERROR',

    CREATE_PRODUCT: 'CREATE_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',

    DELETE_PRODUCT: 'DELETE_PRODUCT',
    IMPORT_PRODUCTS: 'IMPORT_PRODUCTS',
    TEMPLATE_IMPORT_PRODUCTS: 'TEMPLATE_IMPORT_PRODUCTS',
    EXPORT_PRODUCTS: 'EXPORT_PRODUCTS',

    //#region CRUD
    getProducts: (model, _cbError) => ({
        type: actions.GET_PRODUCTS,
        payload: { model },
        _cbError,
    }),
    deleteProduct: (listId, cbSuccess, cbError) => ({
        type: actions.DELETE_PRODUCT,
        payload: { listId },
        cbSuccess, cbError
    }),
    getProductDetail: id => ({
        type: actions.GET_PRODUCT_DETAIL,
        payload: { id }
    }),
    createProduct: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_PRODUCT,
        payload: { model },
        cbSuccess, cbError
    }),
    updateProduct: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_PRODUCT,
        payload: { model },
        cbSuccess, cbError
    }),
    importProducts: (model, _cbSuccess, _cbError) => ({
        type: actions.IMPORT_PRODUCTS,
        payload: { model },
        _cbSuccess, _cbError
    }),
    templateImportProducts: (_cbSuccess, _cbError) => ({
        type: actions.TEMPLATE_IMPORT_PRODUCTS,
        _cbSuccess, _cbError
    }),
    exportProducts: (model, _cbSuccess, _cbError) => ({
        type: actions.EXPORT_PRODUCTS,
        payload: { model },
        _cbSuccess, _cbError
    }),
    //#endregion
}

export default actions;