const actions = {

    GET_CONSIGNEE: 'GET_CONSIGNEE',
    GET_CONSIGNEE_SUCCESS: 'GET_CONSIGNEE_SUCCESS',
    GET_CONSIGNEE_ERROR: 'GET_CONSIGNEE_ERROR',

    GET_CONSIGNEE_DETAIL: 'GET_CONSIGNEE_DETAIL',
    GET_CONSIGNEE_DETAIL_SUCCESS: 'GET_CONSIGNEE_DETAIL_SUCCESS',
    GET_CONSIGNEE_DETAIL_ERROR: 'GET_CONSIGNEE_DETAIL_ERROR',

    CREATE_CONSIGNEE: 'CREATE_CONSIGNEE',
    UPDATE_CONSIGNEE: 'UPDATE_CONSIGNEE',

    DELETE_CONSIGNEE: 'DELETE_CONSIGNEE',

    //#region CRUD
    getConsignee: (model, _cbError) => ({
        type: actions.GET_CONSIGNEE,
        payload: { model },
        _cbError,
    }),
    deleteConsignee: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_CONSIGNEE,
        payload: { id },
        cbSuccess, cbError
    }),
    getConsigneeDetail: id => ({
        type: actions.GET_CONSIGNEE_DETAIL,
        payload: { id }
    }),
    createConsignee: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_CONSIGNEE,
        payload: { model },
        cbSuccess, cbError
    }),
    updateConsignee: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_CONSIGNEE,
        payload: { model },
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;