const actions = {
  GET_CONTAINERS: "GET_CONTAINERS",
  GET_CONTAINERS_SUCCESS: "GET_CONTAINERS_SUCCESS",
  GET_CONTAINERS_ERROR: "GET_CONTAINERS_ERROR",

  CREATE_CONTAINER: "CREATE_CONTAINER",
  UPDATE_CONTAINER: "UPDATE_CONTAINER",
  GET_CONTAINER_DETAIL: "GET_CONTAINER_DETAIL",
  GET_CONTAINER_DETAIL_SUCCESS: "GET_CONTAINER_DETAIL_SUCCESS",
  GET_CONTAINER_DETAIL_ERROR: "GET_CONTAINER_DETAIL_ERROR",

  GET_BL: "GET_BL",
  GET_BL_SUCCESS: "GET_BL_SUCCESS",
  GET_BL_ERROR: "GET_BL_ERROR",

  GET_PALLETS_CONTAINER: "GET_PALLETS_CONTAINER",
  GET_PALLETS_CONTAINER_SUCCESS: "GET_PALLETS_CONTAINER_SUCCESS",
  GET_PALLETS_CONTAINER_ERROR: "GET_PALLETS_CONTAINER_ERROR",

  CREATE_BL: "CREATE_BL",
  UPDATE_BL: "UPDATE_BL",
  IMPORT_PALLETS: "IMPORT_PALLETS",
  CANCEL_CONTAINER: "CANCEL_CONTAINER",
  DELETE_CONTAINER: "DELETE_CONTAINER",

  ADD_PALLETS_CONTAINER: "ADD_PALLETS_CONTAINER",
  DELETE_PALLET_CONTAINER: "DELETE_PALLET_CONTAINER",
  EXPORT_PDF_CONTAINER: "EXPORT_PDF_CONTAINER",
  EXPORT_PDF_CONTAINER_SUCCESS: "EXPORT_PDF_CONTAINER_SUCCESS",
  EXPORT_PDF_CONTAINER_ERROR: "EXPORT_PDF_CONTAINER_ERROR",

  EXPORT_EXCEL_CONTAINER: "EXPORT_EXCEL_CONTAINER",
  CLEAR_CONTAINER_STORAGE: "CLEAR_CONTAINER_STORAGE",

  getContainers: (model, _cbError) => ({
    type: actions.GET_CONTAINERS,
    payload: { model },
    _cbError,
  }),
  createContainer: (body, _cbSuccess, _cbError) => ({
    type: actions.CREATE_CONTAINER,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),
  updateContainer: (body, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_CONTAINER,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),
  getContainerDetail: (containerId, _cbError) => ({
    type: actions.GET_CONTAINER_DETAIL,
    payload: { containerId },
    _cbError,
  }),
  getBL: (containerId, _cbError) => ({
    type: actions.GET_BL,
    payload: { containerId },
    _cbError,
  }),
  createBL: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_BL,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  updateBL: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_BL,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  importPallets: (model, _cbSuccess, _cbError) => ({
    type: actions.IMPORT_PALLETS,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  cancelContainer: (model, _cbSuccess, _cbError) => ({
    type: actions.CANCEL_CONTAINER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  deleteContainer: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_CONTAINER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  getPalletsContainer: (model, _cbError) => ({
    type: actions.GET_PALLETS_CONTAINER,
    payload: { model },
    _cbError,
  }),
  addPalletsContainer: (model, _cbSuccess, _cbError) => ({
    type: actions.ADD_PALLETS_CONTAINER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  deletePalletContainer: (containerId, model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_PALLET_CONTAINER,
    payload: { containerId, model },
    _cbSuccess,
    _cbError,
  }),
  exportPDFContainer: (containerId, _cbError) => ({
    type: actions.EXPORT_PDF_CONTAINER,
    payload: { containerId },
    _cbError,
  }),
  exportExcelContainer: (containerId, _cbSuccess, _cbError) => ({
    type: actions.EXPORT_EXCEL_CONTAINER,
    payload: { containerId },
    _cbSuccess,
    _cbError,
  }),
  clearContainerStorage: () => ({
    type: actions.CLEAR_CONTAINER_STORAGE,
  }),
  //#endregion
};

export default actions;