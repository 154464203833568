import actions from './actions';

const initState = {
  containers: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  containerDetail: {
    detail: {},
    loading: false,
  },
  blDetail: {
    detail: {},
    loading: false,
  },
  palletsContainer: {
    list: [],
    loading: false,
    totalItems: 0,
    sum_total_weight: 0
  },
  containerPdf: {
    detail: {},
    loading: false,
  }
};

export default function containerReducer(state = initState, action) {
  switch (action.type) {

    //#region CONTAINERS
    case actions.GET_CONTAINERS:
      return { ...state, containers: { ...state.containers, loading: true } };
    case actions.GET_CONTAINERS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, containers: { ...state.containers, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_CONTAINERS_ERROR:
      return { ...state, containers: { ...state.containers, loading: false, totalItems: 0, list: [] } };

    case actions.GET_CONTAINER_DETAIL:
      return { ...state, containerDetail: { ...state.containerDetail, loading: true } };
    case actions.GET_CONTAINER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, containerDetail: { ...state.containerDetail, detail: res, loading: false } };
    }
    case actions.GET_CONTAINER_DETAIL_ERROR:
      return { ...state, containerDetail: { ...state.containerDetail, loading: false, detail: {} } };
    //#endregion

    //#region BL
    case actions.GET_BL:
      return { ...state, blDetail: { ...state.blDetail, loading: true } };
    case actions.GET_BL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, blDetail: { ...state.blDetail, detail: res, loading: false } };
    }
    case actions.GET_BL_ERROR:
      return { ...state, blDetail: { ...state.blDetail, loading: false, detail: {} } };
    //#endregion

    //#region PALLET CONTAINER
    case actions.GET_PALLETS_CONTAINER:
      return { ...state, palletsContainer: { ...state.palletsContainer, loading: true } };
    case actions.GET_PALLETS_CONTAINER_SUCCESS: {
      let { data, total, sum_total_weight } = action.payload;
      let res = data ? data : [];
      return { ...state, palletsContainer: { ...state.palletsContainer, list: res, loading: false, totalItems: total, sum_total_weight: sum_total_weight || 0 } };
    }
    case actions.GET_PALLETS_CONTAINER_ERROR:
      return { ...state, palletsContainer: { ...state.palletsContainer, loading: false, totalItems: 0, list: [] } };
    //#endregion

    case actions.EXPORT_PDF_CONTAINER:
      return { ...state, containerPdf: { ...state.containerPdf, loading: true, detail: {} } };
    case actions.EXPORT_PDF_CONTAINER_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, containerPdf: { ...state.containerPdf, detail: res, loading: false } };
    }
    case actions.EXPORT_PDF_CONTAINER_ERROR:
      return { ...state, containerPdf: { ...state.containerPdf, loading: false, detail: {} } };

    case actions.CLEAR_CONTAINER_STORAGE:
      return { ...state, containerPdf: { ...state.containerPdf, detail: {} } }
    default:
      return state;

  }
}
