import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';

export const getShippingCategoryService = model => {
  var url = ApiRouters.SHIPPING_CATEGORY;
  return api.get(url, { params: model });
};
export const deleteShippingCategoryService = id => {
  var url = `${ApiRouters.SHIPPING_CATEGORY}/${id}`;
  return api.delete(url);
};
export const getShippingCategoryDetailService = id => {
  var url = `${ApiRouters.SHIPPING_CATEGORY}/${id}`;
  return api.get(url);
};
export const createShippingCategoryService = body => {
  var url = ApiRouters.SHIPPING_CATEGORY;
  return api.post(url, body);
};
export const updateShippingCategoryService = body => {
  var url = `${ApiRouters.SHIPPING_CATEGORY}/${body.id}`;
  return api.put(url, body);
};

