import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';
import axios from 'axios';

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 300000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data
  };
}

export const getPalletsService = model => {
  var url = ApiRouters.PALLET;
  return api.get(url, { params: model });
};
export const deletePalletService = (listId) => {
  let body = { listId };
  var url = `${ApiRouters.PALLET}`;
  return api.put(url, body);
};
export const getCartonsOfPalletService = model => {
  var url = `${ApiRouters.PALLET}/${model.id}/GetCartonsPallets`;
  return api.get(url, { params: model });
};
export const getProductsOfCartonService = model => {
  var url = `${ApiRouters.PALLET}/${model.id}/GetProductCartons`;
  return api.get(url, { params: model });
};
export const importPalletsService = body => {
  var url = process.env.REACT_APP_API_KEY  + `${ApiRouters.PALLET}/importPallet`;
  return axios(optionsAxios(url, "post", null, body));
};
export const templateImportPalletsService = () => {
  var url = process.env.REACT_APP_API_KEY + `${ApiRouters.PALLET}/Template/importPallet`;
  return axios(optionsAxios(url, "get", null, null));
};

export const createCartonService = model => {
  var url = `${ApiRouters.PALLET}/${model.id}/CreateCartonPallet`;
  return api.post(url, model);
}
export const updateCartonService = model => {
  var url = `${ApiRouters.PALLET}/UpdateCartonPallet/${model.id}`;
  return api.put(url, model);
};
export const deleteCartonService = cartonId => {
  var url = `${ApiRouters.PALLET}/DeleteCartonPallet/${cartonId}`;
  return api.delete(url);
};
export const addProductsCartonService = model => {
  var url = `${ApiRouters.PALLET}/${model.id}/CreateProductCarton`;
  return api.post(url, model.listProduct);
}
export const updateProductsCartonService = model => {
  var url = `${ApiRouters.PALLET}/UpdateProductCarton/${model.id}`;
  return api.put(url, model);
}
export const deleteProductsCartonService = productId => {
  var url = `${ApiRouters.PALLET}/DeleteProductCarton/${productId}`;
  return api.delete(url);
}

