import actions from './actions';
const _ = require("lodash");

const initState = {
  groups: [],
  profileTypes: [],
  provinces: [],
  districts: [],
  wards: [],

  versionApi: "0",
  loading: false,

  productsCarton: {
    products: [],
    loading: false,
    totalItems: 0,
  },
  hardText: {
    data: [],
    loading: false,
  }
};

export default function accountReducer(state = initState, action) {
  switch (action.type) {

    case actions.GET_VERSION_API_SUCCESS: {
      return { ...state, versionApi: action.payload.data };
    }

    //#region GROUP
    case actions.GET_GROUPS_COMMON:
      return { ...state };
    case actions.GET_GROUPS_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, groups: res };
    }
    //#endregion
    //#region PROFILE TYPES
    case actions.GET_PROFILE_TYPES_COMMON:
      return { ...state };
    case actions.GET_PROFILE_TYPES_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, profileTypes: res };
    }
    //#endregion

    //#region ADDRESS
    case actions.GET_PROVINCE_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, provinces: res };
    }
    case actions.GET_DISTRICT_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, districts: res };
    }
    case actions.RESET_DISTRICT_COMMON:
      return { ...state, districts: [] };

    case actions.GET_WARD_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, wards: res };
    }
    case actions.RESET_WARD_COMMON:
      return { ...state, wards: [] };
    //#endregion

    //#region COMMON PRODUCTS
    case actions.GET_COMMON_PRODUCTS_CARTON:
      return { ...state, productsCarton: { ...state.productsCarton, loading: true } };
    case actions.GET_COMMON_PRODUCTS_CARTON_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, productsCarton: { ...state.productsCarton, products: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_COMMON_PRODUCTS_CARTON_ERROR:
      return { ...state, productsCarton: { ...state.productsCarton, loading: false, totalItems: 0, products: [] } };
    //#endregion

    //#region COMMON HARD TEXT
    case actions.GET_COMMON_HARD_TEXT:
      return { ...state, hardText: { ...state.hardText, loading: true } };
    case actions.GET_COMMON_HARD_TEXT_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      let newHard = [];
      _.map(res, item => {
        let temp = item.value ? JSON.parse(item.value) : null;
        let row = {
          ...item,
          value: temp,
        }
        return newHard.push(row);
      });
      return { ...state, hardText: { ...state.hardText, data: newHard, loading: false } };
    }
    case actions.GET_COMMON_HARD_TEXT_ERROR:
      return { ...state, hardText: { ...state.hardText, loading: false, data: [] } };
    //#endregion

    //#region
    case actions.CLEAR_COMMON_STORAGE:
      return { ...state, hardText: { ...state.hardText, data: [] } };
    //#endregion

    case actions.COMMON_RESPONSE_ERROR:
      return { ...state, loading: false }

    default:
      return state;

  }
}
