import PageSize from "@iso/constants/PageSize";
import actions from './actions';

const initState = {
  pallets: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  cartons: {
    list: [],
    totalItems: 0,
    loading: false,
    palletCode: null,
    isEdit: false,
  },
  products: {
    list: [],
    totalItems: 0,
    loading: false,
    palletCode: null,
    isEdit: false,
  },
};

export default function palletReducer(state = initState, action) {
  switch (action.type) {

    //#region PALLETS
    case actions.GET_PALLETS:
      return { ...state, pallets: { ...state.pallets, loading: true } };
    case actions.GET_PALLETS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, pallets: { ...state.pallets, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_PALLETS_ERROR:
      return { ...state, pallets: { ...state.pallets, loading: false, totalItems: 0, list: [] } };
    //#endregion

    //#region CARTONS
    case actions.GET_CARTONS_PALLET:
      return { ...state, cartons: { ...state.cartons, loading: true } };
    case actions.GET_CARTONS_PALLET_SUCCESS: {
      let { list, totalItems, pallet_code, page, isEdit } = action.payload;
      let newCartons = [];
      list?.length > 0 && list.map((item, index) => {
        let carton = {
          ...item,
          numerical_order: index + 1 + PageSize.pallets * (page - 1),
        };
        newCartons.push(carton);
        return newCartons;
      });

      return { ...state, cartons: { ...state.cartons, list: newCartons, loading: false, totalItems: totalItems, palletCode: pallet_code, isEdit: isEdit } };
    }
    case actions.GET_CARTONS_PALLET_ERROR:
      return { ...state, cartons: { ...state.cartons, loading: false, totalItems: 0, list: [], palletCode: null, isEdit: false } };
    //#endregion

    //#region PRODUCTS
    case actions.GET_PRODUCTS_CARTON:
      return { ...state, products: { ...state.products, loading: true } };
    case actions.GET_PRODUCTS_CARTON_SUCCESS: {
      let { list, totalItems, pallet_code, isEdit } = action.payload;
      let res = list ? list : [];
      return { ...state, products: { ...state.products, list: res, loading: false, totalItems: totalItems, palletCode: pallet_code, isEdit: isEdit } };
    }
    case actions.GET_PRODUCTS_CARTON_ERROR:
      return { ...state, products: { ...state.products, loading: false, totalItems: 0, list: [], palletCode: null, isEdit: false } };
    //#endregion

    default:
      return state;

  }
}
