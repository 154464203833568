import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 120000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}

export const getContainersService = (model) => {
  var url = ApiRouters.CONTAINER;
  return api.get(url, { params: model });
};
export const getContainerDetailService = (containerId) => {
  var url = `${ApiRouters.CONTAINER}/${containerId}`;
  return api.get(url);
};
export const createContainerService = (body) => {
  var url = `${ApiRouters.CONTAINER}`;
  return api.post(url, body);
};
export const updateContainerService = (body) => {
  var url = `${ApiRouters.CONTAINER}/${body.id}`;
  return api.put(url, body);
};
export const updateBLService = (body) => {
  var url = `${ApiRouters.CONTAINER}`;
  return api.put(url, body);
};
export const cancelContainerService = (containerId) => {
  var url = `${ApiRouters.CONTAINER}/${containerId}/CancelContainer`;
  return api.put(url);
};
export const deleteContainerService = (containerId) => {
  var url = `${ApiRouters.CONTAINER}/${containerId}/DeleteContainer`;
  return api.delete(url);
};
export const createBLService = (model) => {
  var url = `${ApiRouters.CONTAINER}/${model.id}/ModifyBLContainer`;
  return api.put(url, model);
};
export const getPalletsContainerService = (model) => {
  var url = `${ApiRouters.CONTAINER}/${model.id}/GetContainerPallet`;
  return api.get(url, { params: model });
};
export const addPalletsContainerService = (model) => {
  var url = `${ApiRouters.CONTAINER}/${model.id}/addPalletContainer`;
  return api.put(url, model);
};
export const deletePalletContainerService = (containerId, model) => {
  var url = `${ApiRouters.CONTAINER}/${containerId}/DeleteContainerPallet`;
  return api.put(url, model);
};
export const exportPDFContainerService = (containerId) => {
  var url = `${ApiRouters.CONTAINER}/${containerId}/GetContainerPdf`;
  return api.get(url);
};
export const exportExcelContainerService = (containerId) => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.CONTAINER}/${containerId}/exportExcelContainer`;
  return axios(optionsAxios(url, "get"));
};
export const downloadStatistic = async (containerId) => {
  const url = process.env.REACT_APP_API_KEY + `/api/bill-entry/${containerId}/pdf`;
  try {
    const res = await api.get(url);
    if (res.status === 200) {
      console.log(res);
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};