const baseRouter = "/api/";

export const ApiRouters = {
    LOGIN: baseRouter + "Auth/login",
    FORGOT_PASSWORD: baseRouter + "Auth/ForgotPassword",
    CHECK_TOKEN_RESET_PW: baseRouter + "Auth/CheckResetPassword",
    RESET_PASSWORD: baseRouter + "Auth/ResetPassword",

    ACCOUNT: baseRouter + "Account",
    USER_LOGIN_PROFILE: baseRouter + "Account/Profile",
    CHANGE_PASSWORD: baseRouter + "Account/ChangePassword",

    CUSTOMER: baseRouter + "Customers",
    CUSTOMER_TYPE: baseRouter + "CustomerTypes",
    USER: baseRouter + "User",
    COMMON: baseRouter + "Common",
    ROLE: baseRouter + "Groups",
    CONSIGNEE: baseRouter + "Consignee",
    SHIPPING_CATEGORY: baseRouter + "ShippingCategory",
    PRODUCT: baseRouter + "Product",
    PALLET: baseRouter + "Pallet",
    CONTAINER: baseRouter + "Container",

    LEARNER: baseRouter + "Learners",
}