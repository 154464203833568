import * as commonServices from '@iso/services/common';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region VERSION
export function* getVersionApi() {
  yield takeEvery(actions.GET_VERSION_API, function* ({ payload }) {
    try {
      var response = yield call(commonServices.getVersionApi);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_VERSION_API_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.GET_VERSION_API_ERROR, })
    }
  })
}
//#endregion

//#region GROUP
export function* getGroupsCommon() {
  yield takeEvery(actions.GET_GROUPS_COMMON, function* ({ payload }) {
    try {
      var response = yield call(commonServices.getGroupsCommon);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_GROUPS_COMMON_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR })
    }
  })
}
//#endregion

//#region GROUP
export function* getProfileTypesCommon() {
  yield takeEvery(actions.GET_PROFILE_TYPES_COMMON, function* ({ payload }) {
    try {
      var response = yield call(commonServices.getProfileTypesCommon);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_PROFILE_TYPES_COMMON_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR })
    }
  })
}
//#endregion

//#region ADDRESS
export function* getProvinces() {
  yield takeEvery(actions.GET_PROVINCE_COMMON, function* () {
    try {
      var response = yield call(commonServices.getProvinces);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_PROVINCE_COMMON_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR })
    }
  })
}
export function* getDistricts() {
  yield takeEvery(actions.GET_DISTRICT_COMMON, function* ({ payload }) {
    const { provinceId } = payload;
    try {
      var response = yield call(commonServices.getDistricts, provinceId);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_DISTRICT_COMMON_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR })
    }
  })
}
export function* getWards() {
  yield takeEvery(actions.GET_WARD_COMMON, function* ({ payload }) {
    const { districtId } = payload;
    try {
      var response = yield call(commonServices.getWards, districtId);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_WARD_COMMON_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR })
    }
  })
}
//#endregion

export function* getCommonProductsCartonSaga() {
  yield takeEvery(actions.GET_COMMON_PRODUCTS_CARTON, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(commonServices.getCommonProductsCartonService, model);
      if (response.data?.success) {
        var data = response.data?.data?.list;
        var totalItems = response.data?.data?.total;
        yield put({
          type: actions.GET_COMMON_PRODUCTS_CARTON_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_COMMON_PRODUCTS_CARTON_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* getCommonHardTextSaga() {
  yield takeEvery(actions.GET_COMMON_HARD_TEXT, function* ({ _cbError }) {
    try {
      var response = yield call(commonServices.getCommonHardTextService);
      if (response.data?.success) {
        var data = response.data?.data;
        yield put({
          type: actions.GET_COMMON_HARD_TEXT_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_COMMON_HARD_TEXT_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* updateCommonHardTextSaga() {
  yield takeEvery(
    actions.UPDATE_COMMON_HARD_TEXT,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(commonServices.updateCommonHardTextService, model);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getVersionApi),
    fork(getGroupsCommon),
    fork(getProfileTypesCommon),
    fork(getProvinces),
    fork(getDistricts),
    fork(getWards),
    fork(getCommonProductsCartonSaga),
    fork(getCommonHardTextSaga),
    fork(updateCommonHardTextSaga),
  ])
}