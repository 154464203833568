import actions from './actions';

const initState = {
  products: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  productDetail: {
    detail: {},
    loading: false,
  },
};

export default function productReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_PRODUCTS:
      return { ...state, products: { ...state.products, loading: true } };
    case actions.GET_PRODUCTS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, products: { ...state.products, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_PRODUCTS_ERROR:
      return { ...state, products: { ...state.products, loading: false, totalItems: 0, list: [] } };

    case actions.GET_PRODUCT_DETAIL:
      return { ...state, productDetail: { ...state.productDetail, loading: true } };
    case actions.GET_PRODUCT_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, productDetail: { ...state.productDetail, loading: false, detail: res } };
    }
    case actions.GET_PRODUCT_DETAIL_ERROR:
      return { ...state, productDetail: { ...state.productDetail, loading: false } };
    //#endregion

    default:
      return state;

  }
}
