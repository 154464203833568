import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';

export const getConsigneeService = model => {
  var url = ApiRouters.CONSIGNEE;
  return api.get(url, { params: model });
};
export const deleteConsigneeService = id => {
  var url = `${ApiRouters.CONSIGNEE}/${id}`;
  return api.delete(url);
};
export const getConsigneeDetailService = id => {
  var url = `${ApiRouters.CONSIGNEE}/${id}`;
  return api.get(url);
};
export const createConsigneeService = body => {
  var url = ApiRouters.CONSIGNEE;
  return api.post(url, body);
};
export const updateConsigneeService = body => {
  var url = `${ApiRouters.CONSIGNEE}/${body.id}`;
  return api.put(url, body);
};

