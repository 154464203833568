const actions = {

  GET_PALLETS: 'GET_PALLETS',
  GET_PALLETS_SUCCESS: 'GET_PALLETS_SUCCESS',
  GET_PALLETS_ERROR: 'GET_PALLETS_ERROR',

  DELETE_PALLET: "DELETE_PALLET",

  GET_CARTONS_PALLET: "GET_CARTONS_PALLET",
  GET_CARTONS_PALLET_SUCCESS: "GET_CARTONS_PALLET_SUCCESS",
  GET_CARTONS_PALLET_ERROR: "GET_CARTONS_PALLET_ERROR",

  GET_PRODUCTS_CARTON: "GET_PRODUCTS_CARTON",
  GET_PRODUCTS_CARTON_SUCCESS: "GET_PRODUCTS_CARTON_SUCCESS",
  GET_PRODUCTS_CARTON_ERROR: "GET_PRODUCTS_CARTON_ERROR",

  CREATE_CARTON: "CREATE_CARTON",
  UPDATE_CARTON: "UPDATE_CARTON",
  DELETE_CARTON: "DELETE_CARTON",

  ADD_PRODUCTS_CARTON: "ADD_PRODUCTS_CARTON",
  UPDATE_PRODUCT_CARTON: "UPDATE_PRODUCT_CARTON",
  DELETE_PRODUCT_CARTON: "DELETE_PRODUCT_CARTON",

  IMPORT_PALLETS: 'IMPORT_PALLETS',
  TEMPLATE_IMPORT_PALLETS: 'TEMPLATE_IMPORT_PALLETS',

  //#region CRUD
  getPallets: (model, _cbError) => ({
    type: actions.GET_PALLETS,
    payload: { model },
    _cbError,
  }),
  getCartonsOfPallet: (model, _cbError) => ({
    type: actions.GET_CARTONS_PALLET,
    payload: { model },
    _cbError,
  }),
  getProductsOfCarton: (model, _cbError) => ({
    type: actions.GET_PRODUCTS_CARTON,
    payload: { model },
    _cbError,
  }),
  importPallets: (model, _cbSuccess, _cbError) => ({
    type: actions.IMPORT_PALLETS,
    payload: { model },
    _cbSuccess, _cbError
  }),
  deletePallet: (listId, cbSuccess, cbError) => ({
    type: actions.DELETE_PALLET,
    payload: { listId },
    cbSuccess, cbError
  }),

  createCarton: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_CARTON,
    payload: { model },
    _cbSuccess, _cbError
  }),

  updateCarton: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_CARTON,
    payload: { model },
    _cbSuccess, _cbError
  }),

  deleteCarton: (cartonId, _cbSuccess, _cbError) => ({
    type: actions.DELETE_CARTON,
    payload: { cartonId },
    _cbSuccess, _cbError
  }),

  addProductsCarton: (model, _cbSuccess, _cbError) => ({
    type: actions.ADD_PRODUCTS_CARTON,
    payload: { model },
    _cbSuccess, _cbError
  }),

  updateProductCarton: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_PRODUCT_CARTON,
    payload: { model },
    _cbSuccess, _cbError
  }),

  deleteProductCarton: (productId, _cbSuccess, _cbError) => ({
    type: actions.DELETE_PRODUCT_CARTON,
    payload: { productId },
    _cbSuccess, _cbError
  }),

  templateImportPallets: (_cbSuccess, _cbError) => ({
    type: actions.TEMPLATE_IMPORT_PALLETS,
    _cbSuccess, _cbError
}),
  //#endregion
}

export default actions;