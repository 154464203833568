import actions from './actions';

const initState = {
  consignee: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  consigneeDetail: {
    detail: {},
    loading: false,
  },
};

export default function consigneeReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_CONSIGNEE:
      return { ...state, consignee: { ...state.consignee, loading: true } };
    case actions.GET_CONSIGNEE_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, consignee: { ...state.consignee, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_CONSIGNEE_ERROR:
      return { ...state, consignee: { ...state.consignee, loading: false, totalItems: 0, list: [] } };

    case actions.GET_CONSIGNEE_DETAIL:
      return { ...state, consigneeDetail: { ...state.consigneeDetail, loading: true } };
    case actions.GET_CONSIGNEE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, consigneeDetail: { ...state.consigneeDetail, loading: false, detail: res } };
    }
    case actions.GET_CONSIGNEE_DETAIL_ERROR:
      return { ...state, consigneeDetail: { ...state.consigneeDetail, loading: false } };
    //#endregion

    default:
      return state;

  }
}
