import * as palletsService from "@iso/services/pallet";
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

const [CREATING, UPDATING, DELETING] = [1, 2, 3];

export function* getPalletsSaga() {
  yield takeEvery(actions.GET_PALLETS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(palletsService.getPalletsService, model);
      if (response.data.success) {
        var data = response.data.data.list;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_PALLETS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_PALLETS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* deletePalletSaga() {
  yield takeEvery(
    actions.DELETE_PALLET,
    function* ({ payload, cbSuccess, cbError }) {
      const { listId } = payload;
      try {
        var response = yield call(palletsService.deletePalletService, listId);
        if (response.data.success) {
          yield cbSuccess();
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}

export function* getCartonsOfPalletSaga() {
  yield takeEvery(
    actions.GET_CARTONS_PALLET,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          palletsService.getCartonsOfPalletService,
          model
        );
        if (response.data.success) {
          let { list, total: totalItems, pallet_code, page, isEdit } = response.data.data;
          yield put({
            type: actions.GET_CARTONS_PALLET_SUCCESS,
            payload: { list, totalItems, pallet_code, page, isEdit },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.GET_CARTONS_PALLET_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* getProductsOfCartonSaga() {
  yield takeEvery(
    actions.GET_PRODUCTS_CARTON,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          palletsService.getProductsOfCartonService,
          model
        );
        if (response.data.success) {
          let { list, total: totalItems, pallet_code, isEdit } = response.data.data;
          yield put({
            type: actions.GET_PRODUCTS_CARTON_SUCCESS,
            payload: { list, totalItems, pallet_code, isEdit },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_PRODUCTS_CARTON_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_PRODUCTS_CARTON_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* importPalletsSaga() {
  yield takeEvery(
    actions.IMPORT_PALLETS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(palletsService.importPalletsService, model);
        if (response.status === 201) {
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `ImportPalletsResult_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          yield _cbSuccess();
        } else {
          if (response.status === 202) {
            _cbError("FILE_MALFORMED");
          }
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* tempalteImportPalletsSaga() {
  yield takeEvery(
    actions.TEMPLATE_IMPORT_PALLETS,
    function* ({ _cbSuccess, _cbError }) {
      try {
        var response = yield call(palletsService.templateImportPalletsService);
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `TemplateImportPallets_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.ErrorMessage);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* createCartonSaga() {
  yield takeEvery(
    actions.CREATE_CARTON,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(palletsService.createCartonService, model);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* updateCartonSaga() {
  yield takeEvery(
    actions.UPDATE_CARTON,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(palletsService.updateCartonService, model);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* deleteCartonSaga() {
  yield takeEvery(
    actions.DELETE_CARTON,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { cartonId } = payload;
      try {
        var response = yield call(palletsService.deleteCartonService, cartonId);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* addProductsCartonSaga() {
  yield takeEvery(
    actions.ADD_PRODUCTS_CARTON,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(palletsService.addProductsCartonService, model);
        if (response.data.success) {
          yield _cbSuccess(CREATING);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* updateProductsCartonSaga() {
  yield takeEvery(
    actions.UPDATE_PRODUCT_CARTON,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(palletsService.updateProductsCartonService, model);
        if (response.data.success) {
          yield _cbSuccess(UPDATING);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* deleteProductsCartonSaga() {
  yield takeEvery(
    actions.DELETE_PRODUCT_CARTON,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { productId } = payload;
      try {
        var response = yield call(palletsService.deleteProductsCartonService, productId);
        if (response.data.success) {
          yield _cbSuccess(DELETING);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getPalletsSaga),
    fork(deletePalletSaga),
    fork(getCartonsOfPalletSaga),
    fork(getProductsOfCartonSaga),
    fork(importPalletsSaga),
    fork(tempalteImportPalletsSaga),
    fork(createCartonSaga),
    fork(updateCartonSaga),
    fork(deleteCartonSaga),
    fork(addProductsCartonSaga),
    fork(updateProductsCartonSaga),
    fork(deleteProductsCartonSaga),
  ]);
}
